import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const EmailForm = props => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          mailChimpUrl
          mailChimpToken
        }
      }
    }
  `)
  return (
    <div className="w-100 bg-light-gray pa3">
      <div className="mb4">
        <span className="f3 tc db display dark-gray mb2">
          Abboneer op de nieuwsbrief!
        </span>
        <span className="f5 tc db serif lh-copy">
          Blijf op de hoogte van de laatste cursussen.
        </span>
      </div>
      <form
        className="w-100 db flex flex-wrap items-center justify-around"
        action={data?.site?.siteMetadata?.mailChimpUrl}
        method="POST"
      >
        <input
          type="email"
          name="EMAIL"
          className="ba b--dark-gray bg-near-white pa2 sans-serif w-100 mb2"
          placeholder="Vul je email in..."
        />
        <input
          className="dn"
          type="text"
          name={data?.site?.siteMetadata?.mailChimpToken}
          tabindex="-1"
          value=""
        />
        <button
          type="submit"
          className="ba b--dark-gray bg-dark-gray near-white sans-serif tracked ttu pv2 w-100 pointer"
        >
          Inschrijven
        </button>
      </form>
    </div>
  )
}

export default EmailForm
