import React from "react"
import EmailForm from "./emailForm.js"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import "tachyons"

const Suggested = props => {
  const data = useStaticQuery(graphql`{
  allMarkdownRemark(
    limit: 2
    sort: {order: DESC, fields: frontmatter___date}
    filter: {frontmatter: {type: {eq: "post"}}}
  ) {
    edges {
      node {
        frontmatter {
          title
          slug
          metaDescription
          postImage {
            childImageSharp {
              gatsbyImageData(width: 720, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
}
`)

  return (
    <div
      className="flex flex-column justify-end items-center pa2"
      style={{ gridArea: "suggested" }}
    >
      <span className="sans-serif tracked ttu tc db pv3">
        Misschien iets voor jou?
      </span>
      {data &&
        data.allMarkdownRemark.edges.map(({ node }) => (
          <div className="w-100 mw6 tc mb4">
            <Link to={node.frontmatter.slug}>
              <GatsbyImage
                className="h5"
                image={node.frontmatter.postImage.childImageSharp.gatsbyImageData}
                alt={node.frontmatter.metaDescription}
              />
            </Link>
            <Link
              className="f4 serif tc dib pv2 ph3 display dark-gray no-underline"
              to={node.frontmatter.slug}
            >
              {node.frontmatter.title}
            </Link>
          </div>
        ))}

      <EmailForm />
    </div>
  );
}

export default Suggested
