import React from "react"
import { FaFacebookF } from "react-icons/fa"
import { FiMail } from "react-icons/fi"
import { useStaticQuery, graphql } from "gatsby"
import "tachyons"

const Sidebar = props => {
  let { location } = props
  location = encodeURIComponent(location.pathname)

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)
  const base = encodeURIComponent(data?.site?.siteMetadata?.siteUrl)

  return (
    <div className="dn db-l" style={{ gridArea: "sidebar" }}>
      <div
        className="w3 bg-dark-gray flex flex-wrap"
        style={{ position: "sticky", top: "4rem" }}
      >
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${
            base + location
          }`}
          className="w-100 h3 flex items-center justify-center b near-white"
        >
          <FaFacebookF />
        </a>
        <a
          href={`mailto:?&body=${base + location}`}
          className="w-100 h3 flex items-center justify-center b near-white"
        >
          <FiMail />
        </a>
      </div>
    </div>
  )
}

export default Sidebar
