import * as React from "react"
import Layout from "../common/layout"
import Hero from "./components/hero.js"
import Body from "./components/body.js"
import Seo from "./seo.js"
import MetaSeo from "../common/seo"
import { graphql } from "gatsby"

const Post = ({ location, data }) => {
  const { category, date, dateOriginal, author, title, slug, metaDescription } =
    data.post.frontmatter
  const content = data.post.html

  return (
    <Layout>
      <Seo
        slug={slug}
        title={title}
        date={dateOriginal}
        description={metaDescription}
        author={author}
        image={data.post.frontmatter.postImage.childImageSharp.original.src}
      />
      <MetaSeo title={title} description={metaDescription} />
      <Hero author={author} date={date} category={category} title={title} />
      <Body
        content={content}
        description={metaDescription}
        image={data.post.frontmatter.postImage.childImageSharp.original.src}
        location={location}
      />
    </Layout>
  )
}

export default Post

export const query = graphql`
  query ($slug: String!) {
    post: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMM Do, YYYY")
        dateOriginal: date
        category
        author
        title
        metaDescription
        slug
        postImage {
          childImageSharp {
            original {
              src
            }
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
    date: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        date
      }
    }
  }
`
